var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copy-right border-top"},[_c('ul',{staticClass:"footer1 d-md-flex justify-content-center my-2"},[_c('li',[_c('a',{attrs:{"href":_vm.$auth.setting && _vm.$auth.setting.about_us_url
        ? _vm.$auth.setting.about_us_url
        : '#'}},[_vm._v("About us")])]),_vm._m(0),_vm._m(1),_c('li',[_c('a',{staticClass:"footer-text-color",attrs:{"target":"_blank","href":_vm.$auth.setting.static_pages &&
        _vm.$auth.setting.static_pages[1] &&
        _vm.$auth.setting.static_pages[1].url
        ? _vm.$auth.setting.static_pages[1].url
        : '#'}},[_vm._v("Terms & Conditions")])]),_c('li',[_c('a',{staticClass:"footer-text-color",attrs:{"target":"_blank","href":_vm.$auth.setting.static_pages &&
        _vm.$auth.setting.static_pages[0] &&
        _vm.$auth.setting.static_pages[0].url
        ? _vm.$auth.setting.static_pages[0].url
        : '#'}},[_vm._v("Privacy Policy")])])]),(_vm.$auth && _vm.$auth.setting && _vm.$auth.setting.show_contact_developer == 'Yes')?_c('p',[_c('span',[_vm._v("Powered by Deonde ")])]):_c('p',[_c('span',[_c('a',[_vm._v("Copyright ")]),_vm._v("© "),_c('a',{attrs:{"target":"_blank"}},[_vm._v(_vm._s(_vm.$auth.getAppName()))]),_vm._v(" "+_vm._s(_vm.year))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer-text-color",attrs:{"target":"_blank","href":"/support"}},[_vm._v("Support")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer-text-color",attrs:{"target":"_blank","href":"/faq"}},[_vm._v("FAQ")])])
}]

export { render, staticRenderFns }